import React, { useState, useEffect, ChangeEvent, MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, Link } from 'react-router-dom';
import { checkCircle32F, download32, exclamationMarkTriangle32, gear32, globe32, key32, reset32, user32 } from "@esri/calcite-ui-icons";

function LicensePage({ portalUrl, token, licenseUrl, appAuthId }: { portalUrl: string, token: string, licenseUrl: string, appAuthId: string })
{
    const { productId, subProdId } = useParams<Record<string, string | undefined>>(),
        isSignedIn = (token !== undefined) && (token !== ''),
        oAuthUrl: string = `${portalUrl}/oauth2/authorize?client_id=${appAuthId}&response_type=token&redirect_uri=${window.location.href}`;
    const [working, setWorking] = useState<boolean>(false);
    const [productPurchase, setProductPurchase] = useState<any>(null);
    const [productSubPurchase, setProductSubPurchase] = useState<any>(null);
    const [productDetails, setProductDetails] = useState<any>(null);
    const [portalSelf, setPortalSelf] = useState<any>(null);
    const [syncError, setSyncError] = useState<any>(null);
    const [sendLicReq, setSendLicReq] = useState<boolean>(false);
    const [license, setLicense] = useState<string>('');
    const [appUrls, setAppUrls] = useState<string[]>([]); // The full set
    const [url, setUrl] = useState<string>(''); // A temporary state object for use when typing...
    const [urlIndex, setUrlIndex] = useState<number>(-1);

    useEffect(() =>
    {
        async function loadProductDetails()
        {
            try
            {
                setWorking(true);
                const productSet: any = await fetch(`${portalUrl}/content/listings/${productId}?f=json`).then(rsp => { return rsp.json(); });
                setProductDetails(productSet);
                if (isSignedIn)
                {
                    const self: any = await fetch(`${portalUrl}/portals/self?f=json&token=${token}`).then(rsp => { return rsp.json(); });
                    if (self.error !== undefined) throw new Error(self.error.message);
                    setPortalSelf(self);
                    const bought: any = await fetch(`${portalUrl}/accounts/${self.id}/purchases?f=json&status=active&token=${token}`).then(rsp => { return rsp.json(); }),
                        purchase: any = (bought.purchases !== undefined ?
                            bought.purchases.find((p: { provision: { itemId: undefined; } | undefined; }) => (p.provision !== undefined) && (p.provision.itemId !== undefined) && (p.provision.itemId === productId)) : {});
                    const subPurchase: any = (bought.purchases !== undefined ?
                            bought.purchases.find((p: { provision: { itemId: undefined; } | undefined; }) => (p.provision !== undefined) && (p.provision.itemId !== undefined) && (p.provision.itemId === subProdId)) : {});
                    if (purchase === undefined)
                    {
                        setSyncError({
                            code: 'NoPurchase',
                            message: <FormattedMessage id="license.error.nopurchase.message" defaultMessage="{org} has not purchased {product}" values={{
                                org: <strong className="org-name">{self.name}</strong>,
                                product: <strong className="product-name">{productSet.title}</strong>
                            }} />
                        });
                    }
                    else {
                        setProductPurchase(purchase);
                        setProductSubPurchase(subPurchase);
                    }
                }
                setWorking(false);
            }
            catch (syncErr)
            {
                setSyncError(syncErr);
                setWorking(false);
            }
        }
        loadProductDetails();
    }, [isSignedIn, portalUrl, appAuthId, productId, token]);

    useEffect(() =>
    {
        async function doLicenseRequest() 
        {
            setWorking(true);
            try 
            {
                const formData: FormData = new FormData();
                formData.append('urls', appUrls.join(',').replace(/[\n]/g, ','));
                formData.append('subProductId', subProdId || '');
                //formData.append('product', productId); // Part of URL
                formData.append('token', token);
                const lic: any = await fetch(`${licenseUrl.replace('{product}', productId || '')}`, {
                    method: 'POST',
                    body: formData
                }).then(rsp => { return rsp.json(); });
                setLicense(lic);
                setSyncError(null);
            }
            catch (licErr)
            {
                setSyncError(licErr);
            }
            finally 
            {
                setWorking(false);
                setSendLicReq(false);
            }
        }
        if (sendLicReq)
        {
            doLicenseRequest();
        }
    }, [sendLicReq, isSignedIn, portalUrl, appAuthId, productId, token, licenseUrl, appUrls])

    let productLabel: string = '?';
    if ((productDetails !== undefined) && (productDetails !== null)) productLabel = productDetails['title'] || '?';
    let productMismatch: boolean = false;
    if ((portalSelf !== null) && (portalSelf.appInfo !== undefined)) productMismatch = productId !== portalSelf.appInfo.itemId;
    let downloadUrl: string = '', 
        downloadFile: string = '';
    if ((productDetails !== undefined) && (productDetails !== null) && (license !== undefined) && (license !== null) && (license !== '')) 
    {
        downloadUrl = URL.createObjectURL(new Blob([JSON.stringify(license)], { type: 'application/json' }));
        downloadFile = 'license.json'; //`${(productDetails['title'] || 'product').toLowerCase().replace(/[^a-zA-Z0-9]/g, '-')}-lic.json`
    }

    const onUrlSelect = (e:ChangeEvent<HTMLSelectElement>) =>
    {
        setUrlIndex(e.target.selectedIndex);
    }

    const addUrl = (e: MouseEvent<HTMLButtonElement>) =>
    {
        setAppUrls(urls => {
            const clones: string[] = [...urls];
            if (clones.indexOf(url) < 0) clones.push(url);
            return clones;
        });
        e.preventDefault();
    }

    const removeUrl = (e: MouseEvent<HTMLButtonElement>) =>
    {
        setAppUrls(urls => {
            const clones: string[] = [...urls];
            if (clones.length > urlIndex && urlIndex >= 0) clones.splice(urlIndex, 1);
            return clones;
        });
        e.preventDefault();
    }

    return <div className="license-page container mx-auto">
        {working && <div className="masker">
            <div className="h-48">
                <div className="bg-white px-4 pt-12 pb-12">
                    <div className="sm:flex sm:items-start sm:justify-center">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                            </svg>
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                <FormattedMessage id="license.warning.anonymous.button.signIn" defaultMessage="Loading licensing Information" />
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    <FormattedMessage id="license.warning.syncerror.message" defaultMessage="Please wait"/>
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>}
        {!isSignedIn &&
            <div className="purchase">
                <div className="warning-message">
                {productDetails !== null ? <div className="purchase-thumbnail"><img src={`${portalUrl}/content/listings/${productDetails.itemId}/info/${productDetails.largeThumbnail || productDetails.thumbnail}`} alt="thumbnail" /></div> : null}
                <div className="message-icon">
                    <svg className="btn-icon h-6 px-2 float-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d={exclamationMarkTriangle32} /></svg>
                </div>
                <div className="message-body">
                    <p> <FormattedMessage id="license.warning.anonymous.message" defaultMessage="You are not signed in. Please sign in to your ArcGIS Online account. This step is required to validate your purchase of {productName}."
                            values={{
                                productName: <strong className="product-name">{productLabel}</strong>
                            }}
                        />
                    </p>
                    <p className="btn-holder">
                        <a href={oAuthUrl} className="btn btn-primary">
                            <svg className="btn-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d={user32} /></svg>
                            <span><FormattedMessage id="license.warning.anonymous.button.signIn" defaultMessage="Sign in" /></span>
                        </a>
                        <Link to="/" className="btn">
                            <FormattedMessage id="license.form.button.cancel" defaultMessage="Cancel" />
                        </Link>
                    </p>
                </div>
            </div>
        </div>}
        {(syncError !== undefined) && (syncError !== null) &&
            <div className="warning-message">
                {(syncError.code !== undefined) && (syncError.code === 'NoPurchase') ? <div className="purchase-thumbnail"><img src={`${portalUrl}/content/listings/${productDetails.itemId}/info/${productDetails.largeThumbnail || productDetails.thumbnail}`} alt="thumbnail" /></div> : null}
                <div className="message-icon">
                <svg className="btn-icon  h-6 px-2 float-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d={exclamationMarkTriangle32} /></svg>
                </div>
                <div className="message-body">
                    <p> 
                    {(syncError.code !== undefined) && (syncError.code === 'NoPurchase') ?
                        <>{syncError.message}</> :
                        <FormattedMessage id="license.warning.syncerror.message" defaultMessage="An unexpected error occurred: {detail}. Please try again."
                            values={{
                                detail: syncError.message
                            }}
                        />}
                    </p>
                </div>
            </div>}
        {(productDetails !== null) && <div className="listing">
        </div>}
        {(downloadUrl !== '') && isSignedIn && (productDetails !== null) && (productPurchase !== null) && 
            <div className="purchase">
                <div className="success-message">
                    <div className="purchase-thumbnail mb-10">
                        <img src={`${portalUrl}/content/listings/${productDetails.itemId}/info/${productDetails.thumbnail || productDetails.largeThumbnail}`} alt="thumbnail" /></div>
                    <div className="message-icon" style={{ display: 'none'}}>
                        <svg className="btn-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d={checkCircle32F} /></svg>
                    </div>
                    <div className="message-body">
                        <p className="mb-10"><FormattedMessage id="license.form.success.message" defaultMessage="You have successfully created a new license file for {product}. Click the button below to download the file, then copy it to the correct location on your ArcGIS Enterprise installation. Please consult your product documentation for more information." values={{
                            product: <strong className="product-name">{productLabel}</strong>
                        }} /></p>
                        <div className="flex flex-wrap">
                            <div className="mr-2">
                                <a className="bg-blue-500 flex items-center px-4 py-2 text-white text-xs tracking-wider uppercase whitespace-nowrap" href={downloadUrl} download={downloadFile}>
                                    <svg className="h-6 fill-current stroke-current mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d={download32} /></svg>
                                    <FormattedMessage id="license.form.button.download" defaultMessage="Download License File" />
                                </a>
                            </div>
                            <div>
                                <button className="bg-green-400 flex items-center px-4 py-2 text-green-800 text-xs tracking-wider uppercase whitespace-nowrap" onClick={(e) => {setLicense(''); e.preventDefault()}} type="button">
                                    <svg className="h-6 fill-current stroke-current mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d={reset32} /></svg>
                                    <FormattedMessage id="license.form.button.reset" defaultMessage="Restart" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        {(downloadUrl === '') && isSignedIn && (productDetails !== null) && (productPurchase !== null) ?
            <div className="purchase">
                <p className="font-light mb-3 text-sm">
                    <FormattedMessage 
                        id="license.form.instructions"
                        defaultMessage="Enter the URL(s) of the server(s) where the app is installed. You can generate one licensing file for multiple environments for example, if you have a development or preproduction environment in addition to a live environment." />
                </p>
                <p className="border-b font-light mb-5 pb-5 text-sm">
                    <FormattedMessage 
                    id="license.form.instructions" 
                    defaultMessage="You can get the URL by opening the app from the item in ArcGIS Enterprise. e.g. {url1} or {url2}. You must {link} before you can generate a license."
                    values={{
                        url1: <span className="font-mono">{(productId === 'cd1141c867ec4d9097bcf9f824580995' ? 'https://[serverdetails]/une' : (productId == 'fc5a6c7852824126aa82f400e131953b' ? 'https://[serverdetails]/sweet' : '#'))}</span>,
                        url2: <span className="font-mono">{(productId === 'cd1141c867ec4d9097bcf9f824580995' ? 'https://myuneserver.com/une' : (productId === 'fc5a6c7852824126aa82f400e131953b' ? 'https://mysweetserver.com/sweet' : '#'))}</span>,
                        link: <a href={(productId === 'cd1141c867ec4d9097bcf9f824580995' ? 
                        'https://apps.esriuk.com/app/unehelp/1/wmt/view/2f96af1a5b8646798a5e1b4fe8f5f5cf/builder/#/help/deployment/arcgisent' : 
                        (productId === 'fc5a6c7852824126aa82f400e131953b' ? 'https://apps.esriuk.com/app/sweetarcgis/19/wmt/view/00c37872aa9e46d1b744fe31de6ae9ca/builder/#/help/deployment/arcgisent' : '#'))} target="helpWindow">
                            <FormattedMessage id="license.form.instructions" defaultMessage="install the app" />
                        </a>
                    }} /></p>
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="mt-5 md:mt-0 md:col-span-3">
                            <form action="#" method="POST">
                                <div className="overflow-hidden">
                                    <div>
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="col-span-6">
                                                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                                                    <FormattedMessage id="license.form.label.product" defaultMessage="Product" />
                                                </label>
                                                <div className="block border border-dashed border-gray-300 flex focus:border-indigo-500 focus:ring-indigo-500 h-10 items-center mt-1 px-4 shadow-sm sm:text-sm uppercase w-full">
                                                    <span>{productLabel}</span>
                                                </div>
                                            </div>

                                            {/* Display number of Builder Licenses */}
                                            <div className={`${productId === 'cd1141c867ec4d9097bcf9f824580995' ? 'col-span-6' : 'col-span-3'}`}>
                                                <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                                                    <FormattedMessage id="license.form.label.users" defaultMessage="Builder Licenses" />
                                                </label>
                                                <div className="block border border-dashed border-gray-300 flex focus:border-indigo-500 focus:ring-indigo-500 h-10 items-center mt-1 px-4 shadow-sm sm:text-sm uppercase w-full">
                                                    <span>{productPurchase.provision.orgEntitlements.maxUsers}</span>
                                                </div>
                                            </div>
                                            {/* Display number of Data Editor Licenses */}
                                            <div className={`${productId === 'cd1141c867ec4d9097bcf9f824580995' ? 'hidden' : 'col-span-3'}`}>
                                                <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                                                    <FormattedMessage id="license.form.label.users" defaultMessage="Data Editor Licenses" />
                                                </label>
                                                <div className="block border border-dashed border-gray-300 flex focus:border-indigo-500 focus:ring-indigo-500 h-10 items-center mt-1 px-4 shadow-sm sm:text-sm uppercase w-full">
                                                    <span>{productSubPurchase?.provision?.orgEntitlements?.maxUsers || 0}</span>
                                                </div>
                                            </div>

                                            <div className="col-span-12">
                                            <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Deployment URL(s)</label>
                                            
                                            <div className="flex mb-2">
                                                <input id="url"
                                                    value={url}
                                                    type="url" pattern="^(http|https):\/\/[a-zA-Z0-9.\-_:]+\/(.*)$"
                                                    className={`block mr-2 border flex focus:border-indigo-500 focus:ring-indigo-500 h-10 items-center px-4 sm:text-sm w-full form-control ${(/^(http|https):\/\/[a-zA-Z0-9.\-_:]+\/(.*)$/.test(url) ? 'url-valid' : 'url-invalid')}`}
                                                    placeholder={`URL of ${productLabel} in your organisation`}
                                                    onChange={e =>
                                                    {
                                                        setUrl(e.target.value);
                                                    }} />
                                                <div className="flex">
                                                    <button className={`${!(/^(http|https):\/\/[a-zA-Z0-9.\-_:]+\/(.*)$/.test(url)) ? 'opacity-50 cursor-default hover-bg-blue-500': 'hover:bg-blue-600'} mr-2 bg-blue-500 border border-transparent flex focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 font-medium inline-flex items-center justify-center px-4 py-2 shadow-sm text-white text-xs tracking-wider uppercase`} onClick={addUrl} disabled={!(/^(http|https):\/\/[a-zA-Z0-9.\-_:]+\/(.*)$/.test(url))}>
                                                        <FormattedMessage id="license.form.button.addUrl" defaultMessage="Add" />
                                                    </button>
                                                    <button className={`${urlIndex < 0 || urlIndex >= appUrls.length ? 'opacity-50 cursor-default hover-bg-blue-500': 'hover:bg-blue-600'} bg-blue-500 border border-transparent flex focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 font-medium inline-flex items-center justify-center px-4 py-2 shadow-sm text-white text-xs tracking-wider uppercase`} onClick={removeUrl} disabled={urlIndex < 0 || urlIndex >= appUrls.length}>
                                                        <FormattedMessage id="license.form.button.removeUrl" defaultMessage="Remove" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div>
                                                <select className="block border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 mt-1 px-4 py-2 shadow-sm sm:text-sm w-full" size={5} multiple={false} onChange={onUrlSelect}>
                                                    {appUrls.map((u, i) => 
                                                    {
                                                        return <option key={i} value={u}>{u}</option>;
                                                    })}
                                                </select>
                                            </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="pt-5 text-right">
                                        <button 
                                            className="bg-blue-500 border border-transparent flex focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 font-medium hover:bg-blue-600 inline-flex items-center justify-center px-4 py-2 shadow-sm text-white text-xs tracking-wider uppercase"
                                            type="button"
                                            onClick={(e) => {setSendLicReq(true); e.preventDefault(); }}
                                            disabled={(appUrls.length < 1)}
                                        >
                                            <FormattedMessage id="license.form.button.submit" defaultMessage="Get License File" />
                                        </button>
                                        <Link to="/" className="ml-3 bg-transparent border border-transparent flex focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 font-medium inline-flex items-center justify-center px-4 py-2 text-gray-500 text-xs tracking-wider uppercase">
                                            <FormattedMessage id="license.form.button.cancel" defaultMessage="Cancel" />
                                        </Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
        </div> : null}
    </div>;
}

export default LicensePage;