import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, Link } from 'react-router-dom';
import './LicensePage.scss';
import { download32, exclamationMarkTriangle32, gear32, user32 } from "@esri/calcite-ui-icons";

function DownloadPage({ portalUrl, token, appAuthId, mediaUrl, product }: { portalUrl: string, token: string, appAuthId: string, mediaUrl: string, product?: any })
{
    const productId = (product !== undefined && product !== null && product.id !== undefined ?
        product.id :
        useParams<Record<string, string | undefined>>().productId),
        isSignedIn = (token !== undefined) && (token !== ''),
        oAuthUrl: string = `${portalUrl}/oauth2/authorize?client_id=${appAuthId}&response_type=token&redirect_uri=${window.location.href}`,
        downloadPath: string = (product !== undefined && product !== null && product.downloadPath !== undefined ?
            product.downloadPath :
            '/download-file/{filename}'), // Was '/download-file/{product}/{filename}' but product ID is part of pathname from service
        listPath: string = '/list-files/{product}', 
        singleFile: boolean = (product !== undefined && product !== null && product.single !== undefined ? product.single : false);
    const [working, setWorking] = useState<boolean>(false);
    const [productPurchase, setProductPurchase] = useState<any>(null);
    const [productDetails, setProductDetails] = useState<any>(null);
    const [productMedia, setProductMedia] = useState<any[]>([]);
    const [portalSelf, setPortalSelf] = useState<any>(null);
    const [syncError, setSyncError] = useState<any>(null);

    useEffect(() =>
    {
        async function loadProductDetails()
        {
            try
            {
                setWorking(true);
                // Hard-wired product information - no need to access web/cloud resources
                if (product !== undefined && product !== null && product.anonymous !== undefined && product.anonymous === true)
                {
                    setProductDetails({
                        ...product,
                        anonymous: true
                    });
                    setProductPurchase({});
                    try
                    {
                        const productMediaUrl: string = `${mediaUrl}${listPath}`.replace('{product}', productId || ''),
                            mediaList: any = await fetch(`${productMediaUrl}?f=json`).then(rsp => { return rsp.json(); });
                        setProductMedia(mediaList);
                    }
                    catch (mediaEx)
                    {
                        setProductMedia([]);
                    }
                }
                // Standard cloud-based mechanism - product information comes from Marketplace etc.
                else
                {
                    const productSet: any = await fetch(`${portalUrl}/content/listings/${productId}?f=json`).then(rsp => { return rsp.json(); });
                    setProductDetails({
                        ...productSet,
                        anonymous: false
                    });
                    if (isSignedIn)
                    {
                        const self: any = await fetch(`${portalUrl}/portals/self?f=json&token=${token}`).then(rsp => { return rsp.json(); });
                        if (self.error !== undefined) throw new Error(self.error.message);
                        setPortalSelf(self);
                        const bought: any = await fetch(`${portalUrl}/accounts/${self.id}/purchases?f=json&status=active&token=${token}`).then(rsp => { return rsp.json(); }),
                            purchase: any = (bought.purchases !== undefined ?
                                bought.purchases.find((p: { provision: { itemId: undefined; } | undefined; }) => (p.provision !== undefined) && (p.provision.itemId !== undefined) && (p.provision.itemId === productId)) : {});
                        if (purchase === undefined)
                        {
                            setSyncError({
                                code: 'NoPurchase',
                                message: <FormattedMessage id="license.error.nopurchase.message" defaultMessage="{org} has not purchased {product}" values={{
                                    org: <strong className="org-name">{self.name}</strong>,
                                    product: <strong className="product-name">{productSet.title}</strong>
                                }} />
                            });
                        }
                        else 
                        {
                            setProductPurchase(purchase);
                            const productMediaUrl: string = `${mediaUrl}${listPath}`.replace('{product}', productId || ''),
                                mediaList: any = await fetch(`${productMediaUrl}?f=json&token=${token}`).then(rsp => { return rsp.json(); });
                            setProductMedia(mediaList);
                        }
                    }
                }
                setWorking(false);
            }
            catch (syncErr)
            {
                setSyncError(syncErr);
                setWorking(false);
            }
        }
        loadProductDetails();
    }, [isSignedIn, portalUrl, appAuthId, productId, token, product]);

    let productLabel: string = '?';
    if ((productDetails !== undefined) && (productDetails !== null)) productLabel = productDetails['title'] || '?';
    let productMismatch: boolean = false;
    if ((portalSelf !== null) && (portalSelf.appInfo !== undefined)) productMismatch = productId !== portalSelf.appInfo.itemId;
    const anonymousProduct = (product !== undefined && product !== null && product.anonymous !== undefined && product.anonymous === true) ||
            (productDetails !== undefined && productDetails !== null && productDetails.anonymous !== undefined && productDetails.anonymous === true),
        productDownloads: any[] = productMedia.filter(m => m.pathname.indexOf('media/') > 0),
        productDocs: any[] = productMedia.filter(m => m.pathname.indexOf('docs/') > 0), 
        productMediaUrl = (anonymousProduct && product !== undefined && product !== null && product.mediaUrl !== undefined ?
            product.mediaUrl :
            mediaUrl);
    productDownloads.sort((a, b) =>
    {
        return b.modified.toString().localeCompare(a.modified.toString());
    });
    /*
    let productMedia: any[] = [{
        name: "Demo Link | Media on ArcGIS Online", 
        filename: 'linkedmedia.zip',
        href: "https://www.arcgis.com/sharing/rest/content/items/<GUID>/data"
    }],
        productDocs: any[] = [];
    */
    return <div className="download-page mx-auto">
        {!isSignedIn && !anonymousProduct &&
            <div className="purchase">
                <div className="warning-message">
                    <div className="bg-white px-4 pt-12 pb-12">
                        <div className="sm:flex sm:items-start sm:justify-center">
                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                </svg>
                            </div>
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                    <FormattedMessage id="downloads.warning.anonymous.message" defaultMessage="Please sign in to your ArcGIS Online account" />
                                </h3>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        <FormattedMessage
                                            id="downloads.warning.anonymous.message"
                                            defaultMessage="This step is required to validate your purchase of {productName}."
                                            values={{
                                                productName: <strong className="product-name">{productLabel}</strong>
                                            }}
                                        />
                                    </p>
                                </div>
                                <div className="pt-5 text-right flex">
                                    <a href={oAuthUrl} className="bg-blue-500 flex items-center px-4 py-2 text-white text-xs tracking-wider uppercase">
                                        <span>
                                            <FormattedMessage id="downloads.warning.anonymous.button.signIn" defaultMessage="Sign in" />
                                        </span>
                                    </a>
                                    <Link to="/" className="underline ml-3 bg-transparent border border-transparent flex focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 font-medium inline-flex items-center justify-center px-4 py-2 text-gray-500 text-xs tracking-wider uppercase">
                                        <FormattedMessage id="downloads.form.button.cancel" defaultMessage="Cancel" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        {(syncError !== undefined) && (syncError !== null) &&
            <div className="warning-message">
                {(syncError.code !== undefined) && (syncError.code === 'NoPurchase') ? <div className="purchase-thumbnail"><img src={`${portalUrl}/content/listings/${productDetails.itemId}/info/${productDetails.largeThumbnail || productDetails.thumbnail}`} alt="thumbnail" /></div> : null}
                <div className="bg-white px-4 pt-12 pb-12">
                    <div className="sm:flex sm:items-start sm:justify-center">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                            </svg>
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                {(syncError.code !== undefined) && (syncError.code === 'NoPurchase') ?
                                    <>{syncError.message}</> :
                                    <FormattedMessage id="license.warning.syncerror.message" defaultMessage="An unexpected error occurred" />}
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    {(syncError.code !== undefined) && (syncError.code === 'NoPurchase') ?
                                        <>{syncError.message}</> :
                                        <FormattedMessage id="license.warning.syncerror.message" defaultMessage="{detail}. Please try again."
                                            values={{
                                                detail: syncError.message
                                            }}
                                        />}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        {(productDetails !== null) && <div className="listing">
        </div>}
        {(productDetails !== null) && (anonymousProduct || (isSignedIn && productPurchase !== null)) ?
            <div className="purchase">
                <div className="container mx-auto px-6 py-12">
                    <div className="md:grid md:grid-cols-2">
                        <div className="w-full">
                            <img className="w-full object-contain align-top max-w-lg mx-auto" src={(productDetails.thumbnail !== undefined && productDetails.thumbnail.indexOf('http') === 0 ?
                                productDetails.thumbnail :
                                `${portalUrl}/content/listings/${productDetails.itemId}/info/${productDetails.largeThumbnail || productDetails.thumbnail}`)} alt={productLabel} />
                        </div>
                        <div className="w-full max-w-lg mx-auto mt-5 md:ml-8 md:mt-0">
                            <p className="text-gray-700 text-md">
                                <FormattedMessage
                                    id="downloads.form.instructions"
                                    defaultMessage="Download the installation media for {product} below. See how to install the application {link}." values={{
                                        product: <strong className="product-name">{productLabel}</strong>,
                                        link: <a className="text-blue-500" href={(productId === 'cd1141c867ec4d9097bcf9f824580995' ? 
                                            'https://apps.esriuk.com/app/unehelp/1/wmt/view/2f96af1a5b8646798a5e1b4fe8f5f5cf/builder/#/help/deployment/arcgisent' : 
                                            (productId == 'fc5a6c7852824126aa82f400e131953b' ? 'https://apps.esriuk.com/app/sweetforarcgis/3/wmt/view/c9259600c1ca4185a8a8422f7fbd5452/builder/#/help/deployment/arcgisent' : 
                                            productDetails.url))} target="helpWindow"><FormattedMessage id="downloads.form.instructions.link" defaultMessage="here" /></a>
                                    }}
                                />
                            </p>
                            <hr className="my-3" />

                            <h3 className="mb-1.5 text-gray-700 text-lg uppercase">
                                <FormattedMessage id="downloads.form.label.media" defaultMessage="Installation Media" />
                            </h3>
                            {working && <div className="masker">
                                <div className="h-48">
                                    <div className="bg-white px-4 pt-12 pb-12">
                                        <div className="sm:flex sm:items-start sm:justify-center">
                                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                                </svg>
                                            </div>
                                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                                    <FormattedMessage id="license.warning.anonymous.button.signIn" defaultMessage="Loading" />
                                                </h3>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        <FormattedMessage id="license.warning.syncerror.message" defaultMessage="Please wait" />
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {!working && <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {(productDownloads.length > 0 ?
                                            (singleFile ? productDownloads.slice(0, 1) : productDownloads).map((m, i) =>
                                            {
                                                const href: string = (m.href !== undefined ? m.href : `${productMediaUrl}${downloadPath}`.replace('{product}', productDetails.itemId).replace('{filename}', m.pathname));
                                                const dname: string = (m.name.split(','));
                                                const dversion: string = (dname[1].substring(0, dname[1].length - 6));
                                                const dtype: any = (dname[1].substring(dname[1].length - 4, dname[1].length - 1));
                                                return <tr key={i}>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="flex items-center">
                                                            <div className="flex-shrink-0 h-10 w-10">
                                                                <a href={`${href}?token=${token}`} download={m.filename}>
                                                                    <div className="mx-auto flex-shrink-0 flex text-blue-600 items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                                                        <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                                                                            <path fill="none" d="M0 0h24v24H0z" />
                                                                            <path d="M3 19h18v2H3v-2zm10-5.828L19.071 7.1l1.414 1.414L12 17 3.515 8.515 4.929 7.1 11 13.17V2h2v11.172z" />
                                                                        </svg>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div className="ml-4">
                                                                <div className="text-sm font-medium text-gray-900">
                                                                    <a href={`${href}?token=${token}`} download={m.filename} className="text-gray-600 text-xs tracking-wide uppercase"><span className="mr-1">{dname[0]}</span><span className="bg-green-200 mx-1 px-2 py-1 rounded text-green-600">{dversion}</span><span className="bg-blue-100 text-blue-500 rounded py-1 px-2 mx-1">{dtype}</span></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>;
                                            }) :
                                            <tr>
                                                <td colSpan={2} className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0 h-10 w-10">
                                                            <div className="mx-auto flex-shrink-0 flex text-blue-600 items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                                                <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                                    <path d="M3 19h18v2H3v-2zm10-5.828L19.071 7.1l1.414 1.414L12 17 3.515 8.515 4.929 7.1 11 13.17V2h2v11.172z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className="ml-4">
                                                            <div className="text-sm font-medium text-gray-900">
                                                                No downloadable media available
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>}
                            {(productDocs.length > 0) &&
                                <div className="row">
                                    <div className="col col-md-4">
                                        <span className="control-label">
                                            <FormattedMessage id="downloads.form.label.documents" defaultMessage="Documents" />
                                        </span>
                                    </div>
                                    <div className="col col-md-8">
                                        <ul className="resource-list documentation-list">
                                            {(productDocs.length > 0 ?
                                                productDocs.map((m, i) =>
                                                {
                                                    const href: string = (m.href !== undefined ? m.href :
                                                        `${productMediaUrl}${downloadPath}`.replace('{product}', productDetails.itemId).replace('{filename}', m.filename));
                                                    return <li key={i}>
                                                        <a href={`${href}?token=${token}`} className="media-link">
                                                            <svg className="btn-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d={download32} /></svg>
                                                            {m.name}
                                                        </a>
                                                    </li>;
                                                }) :
                                                <li className="font-light text-gray-500">No downloadable media available</li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div> : null}
    </div>;
}

export default DownloadPage;