import React from 'react';
import ReactDOM from 'react-dom';
import {IntlProvider} from 'react-intl';
//import './index.css';
import './assets/main.css';
import LicensingApp from './LicensingApp';
import * as appconfig from './config.json';
import * as serviceWorker from './serviceWorker';
import QueryString from 'query-string';

// One central point to pick up the access_token because ArcGIS Online will 
// always round-trip therefore the app is effectively restarting so comes through this block
let token: string = '', 
  authErr: any = null;
if (window.location.hash)
{
  const hash: QueryString.ParsedQuery = QueryString.parse(window.location.hash);
  if (hash['access_token'] !== undefined) token = (hash['access_token'] || '').toString();
  if (hash['error'] !== undefined) 
  {
    const key: string = (hash['error'] || '').toString(),
      desc: string = (hash['error_description'] || '').toString();
    authErr = new Error(((key !== 'access_denied') && (desc !== 'The user denied your request.') ? desc : 'UserCancel'));
  }
}

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale={navigator.language} defaultLocale="en-GB">
      <LicensingApp 
        portalUrl={appconfig.portalUrl}
        appAuthId={appconfig.appAuthId}
        token={token}
        productIds={appconfig.productIds}
        subProductIds={appconfig.subProductIds}
        licenseUrl={appconfig.licenseUrl}
        mediaUrl={appconfig.mediaUrl}
        cdnMediaUrl={appconfig.cdnMediaUrl}
        signInError={authErr}
        />
      </IntlProvider>
  </React.StrictMode>,
  document.getElementById('licensingAppRoot')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
