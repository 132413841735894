import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { download32, exclamationMarkTriangle32, gear32, key32, user32 } from "@esri/calcite-ui-icons";
import { isNullOrUndefined } from 'util';

function HomePage({ portalUrl, token, productIds, subProductIds, appAuthId, user, autoAuthorize = true }: { portalUrl: string, token: string, productIds: string[], subProductIds: any[], appAuthId: string, user?: any, autoAuthorize?: boolean }) 
{
    const [working, setWorking] = useState(false);
    const [products, setProducts] = useState<any>([]);
    const [subProducts, setSubProducts] = useState<any>([]);
    const [self, setSelf] = useState<any>({});
    const [syncError, setSyncError] = useState<any>(null);
    const isSignedIn = (token !== undefined) && (token !== ''),
        oAuthPath = window.location.href.split('#')[0].split('?')[0],
        oAuthUrl: string = `${portalUrl}/oauth2/authorize?client_id=${appAuthId}&response_type=token&redirect_uri=${oAuthPath}`;

    useEffect(() =>
    {
        async function loadProductDetails() 
        {
            setWorking(true);
            try 
            {
                const portalSelf: any = await fetch(`${portalUrl}/portals/self?f=json&token=${token}`).then(rsp => { return rsp.json(); });
                if (portalSelf.error !== undefined) 
                {
                    setSelf(null);
                    throw new Error(portalSelf.error.message);
                }
                setSelf(portalSelf);
                const bought: any = await fetch(`${portalUrl}/accounts/${portalSelf.id}/purchases?f=json&status=active&token=${token}`).then(rsp => { return rsp.json(); }),
                    productSet: any = [];
                const subProductSet: any = [];
                if (bought.error !== undefined) throw new Error(bought.error.message);
                for (let id of productIds) 
                {
                    const details: any = await fetch(`${portalUrl}/content/listings/${id.split(':')[0]}?f=json`).then(rsp => { return rsp.json(); }),
                        purchase: any = (bought.purchases !== undefined ?
                            bought.purchases.find((p: { provision: { itemId: undefined; } | undefined; }) => (p.provision !== undefined) && (p.provision.itemId !== undefined) && (p.provision.itemId === id.split(':')[0])) : {});
                    if ((purchase !== undefined) && (purchase.provision !== undefined)) productSet.push(details);
                }
                for (let subProd of subProductIds)
                {
                    // Get details of subproducts and add to setSubProducts so they can be passed to the licensing page
                    const purchase: any = (bought.purchases !== undefined ? bought.purchases.find(
                        (p: { provision: { itemId: undefined; } | undefined; }) =>
                            (p?.provision?.itemId === subProd.subId)) : {});
                    if ((purchase?.provision !== undefined))
                    {
                        subProductSet.push(subProd);
                    }
                }
                setWorking(false);
                setProducts(productSet);
                setSubProducts(subProductSet);
            }
            catch (syncErr)
            {
                setSyncError(syncErr);
                setWorking(false);
            }
        }
        if (isSignedIn) loadProductDetails();
        else if (autoAuthorize) window.location.href = oAuthUrl;
    }, [isSignedIn]);

    const productList = products.map((listing: any, pi: number) =>
    {
        const subProductId: { prodId: string, subId: string } = subProducts.find((subProd: any) =>
        {
            return subProd.prodId === listing.itemId;
        });
        return <div key={pi} className="w-full lg:w-1/2 md:px-4 py-5">
            <div className="bg-white h-full rounded border shadow-lg hover:shadow-xl" key={listing.itemId}>
                <div className="px-4 py-6 md:px-8">
                    <a href={`https://marketplace.arcgis.com/listing.html?id=${listing.itemId}`} target="marketplaceWindow">
                        <h1 className="text-lg">{listing.title}</h1>
                    </a>
                    <p className="py-4 font-light h-24">{listing.snippet}</p>
                    <div className="flex flex-wrap">
                        <div className="mr-2">
                            <Link to={`/resources/${listing.itemId}`} className="bg-blue-500 flex items-center px-4 py-2 text-white text-xs tracking-wider uppercase">
                                <svg className="fill-current mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z" /><path d="M13 13v5.585l1.828-1.828 1.415 1.415L12 22.414l-4.243-4.242 1.415-1.415L11 18.585V13h2zM12 2a7.001 7.001 0 0 1 6.954 6.194 5.5 5.5 0 0 1-.953 10.784v-2.014a3.5 3.5 0 1 0-1.112-6.91 5 5 0 1 0-9.777 0 3.5 3.5 0 0 0-1.292 6.88l.18.03v2.014a5.5 5.5 0 0 1-.954-10.784A7 7 0 0 1 12 2z" /></svg>
                                <span>
                                    <FormattedMessage id="home.product.button.download" defaultMessage="Download Product" values={{ name: listing.title }} />
                                </span>
                            </Link>
                        </div>
                        <div>
                            <Link to={`/license/${listing.itemId}/${subProductId?.subId || 'null'}`} className="bg-green-400 flex items-center px-4 py-2 text-green-800 text-xs tracking-wider uppercase">
                                <svg className="fill-current mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z" /><path d="M12.917 13A6.002 6.002 0 0 1 1 12a6 6 0 0 1 11.917-1H23v2h-2v4h-2v-4h-2v4h-2v-4h-2.083zM7 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8z" /></svg>
                                <span>
                                    <FormattedMessage id="home.product.button.license" defaultMessage="Create License" values={{ name: listing.title }} />
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    });

    return <div className="home-page">
        {(syncError !== undefined) && (syncError !== null) &&
            <div className="warning-message p-3">
                <div className="message-icon">
                    <svg className="btn-icon h-6 px-2 float-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d={exclamationMarkTriangle32} /></svg>
                </div>
                <div className="message-body">
                    <p>
                        {(syncError.code !== undefined) && (syncError.code === 'NoPurchase') ?
                            <>{syncError.message}</> :
                            <FormattedMessage id="license.warning.syncerror.message" defaultMessage="An unexpected error occurred: {detail}. Please try again."
                                values={{
                                    detail: syncError.message.replace(/\.$/, '')
                                }}
                            />}
                    </p>
                </div>
            </div>}
        {working ?
            <div className="masker">
                <div className="h-48">
                    <div className="bg-white px-4 pt-12 pb-12">
                        <div className="sm:flex sm:items-start sm:justify-center">
                            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                </svg>
                            </div>
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                    <FormattedMessage id="license.warning.anonymous.button.signIn" defaultMessage="Loading Application Licensing Hub" />
                                </h3>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        <FormattedMessage id="license.warning.syncerror.message" defaultMessage="Please wait" />
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div> :
            <div className="page-content">
                {isSignedIn && !isNullOrUndefined(user) && !isNullOrUndefined(self) ?
                    <>
                        <div className="relative bg-white overflow-hidden mb-10">
                            <div className="max-w-7xl mx-auto">
                                <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                                    <svg className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
                                        <polygon points="50,0 100,0 50,100 0,100" />
                                    </svg>
                                    <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
                                        <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                                            <div className="px-5 pt-4 flex items-center justify-between">
                                                <div>
                                                    <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <main className="pt-20 mx-auto max-w-7xl">
                                        <div className="sm:text-center lg:text-left">
                                            <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-5xl">
                                                <span className="block mb-1.5">
                                                    <FormattedMessage id="home.welcome.signedin.message" defaultMessage="Welcome to the" values={{
                                                        user: <strong className="user-name">{((user !== undefined) && (user !== null) ? user.fullName : '')}</strong>
                                                    }} />
                                                </span>
                                                <span className="block text-blue-600">
                                                    <FormattedMessage id="home.welcome.signedin.message" defaultMessage="Application Licensing Hub" values={{
                                                        user: <strong className="user-name">{((user !== undefined) && (user !== null) ? user.fullName : '')}</strong>
                                                    }} />
                                                </span>
                                            </h1>
                                            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                                <FormattedMessage id="home.welcome.signedin.message" defaultMessage="From here you can find and download the latest releases and generate licencing files." />
                                            </p>
                                        </div>
                                    </main>
                                </div>
                            </div>
                            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                                <img className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80" alt="" />
                            </div>
                        </div>
                        {productList.length > 0 ?
                            <div className="flex flex-wrap container mx-auto mb-10">
                                {productList}
                            </div> :
                            <p>
                                <FormattedMessage id="home.welcome.signedin.noproducts" defaultMessage="Your ArcGIS Online organisation ({org}) does not appear to have purchased any Esri UK applications. Please review your purchases at {marketplace}." values={{
                                    org: self.name,
                                    marketplace: <a href="https://marketplace.arcgis.com/index.html?q=esriuk&amp;transaction=paid" target="marketplaceWindow">ArcGIS Marketplace</a>
                                }} />
                            </p>
                        }

                    </>
                    :
                    <>
                        <div className="relative bg-white overflow-hidden mb-10">
                            <div className="max-w-7xl mx-auto">
                                <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                                    <svg className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
                                        <polygon points="50,0 100,0 50,100 0,100" />
                                    </svg>
                                    <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
                                        <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                                            <div className="px-5 pt-4 flex items-center justify-between">
                                                <div>
                                                    <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <main className="pt-20 mx-auto max-w-7xl">
                                        <div className="sm:text-center lg:text-left">
                                            <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-5xl">
                                                <span className="block mb-1.5">
                                                    <FormattedMessage id="home.welcome.signedin.message" defaultMessage="Welcome to the" values={{
                                                        user: <strong className="user-name">{((user !== undefined) && (user !== null) ? user.fullName : '')}</strong>
                                                    }} />
                                                </span>
                                                <span className="block text-blue-600">
                                                    <FormattedMessage id="home.welcome.signedin.message" defaultMessage="Application Licensing Hub" values={{
                                                        user: <strong className="user-name">{((user !== undefined) && (user !== null) ? user.fullName : '')}</strong>
                                                    }} />
                                                </span>
                                            </h1>
                                            <p className="my-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                                <FormattedMessage id="home.welcome.signedin.message" defaultMessage="From here you can create license files for your ArcGIS for Enterprise apps and download installation media, patches and help resources." />
                                            </p>
                                            <a href={oAuthUrl} className="bg-blue-500 inline-flex items-center px-7 py-3 text-white text-xs tracking-wider uppercase">
                                                <span><FormattedMessage id="home.welcome.anonymous.button.signIn" defaultMessage="Sign in" /></span>
                                            </a>
                                        </div>
                                    </main>
                                </div>
                            </div>
                            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                                <img className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80" alt="" />
                            </div>
                        </div>
                    </>}
            </div>
        }
    </div>;
}

export default HomePage;