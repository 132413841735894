import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// import './LicensingApp.scss';
import esriukLogo from './assets/img/footer/blogo.svg';
import HomePage from './HomePage';
import LicensePage from './LicensePage';
import DownloadPage from './DownloadPage';
import { ReactComponent as WarningIcon } from './assets/img/messages/warnmessage.svg';
import { applications32, home32 } from '@esri/calcite-ui-icons';

function LicensingApp({
    portalUrl = 'https://www.arcgis.com/sharing/rest',
    appAuthId,
    token,
    productIds = [],
    subProductIds = [],
    licenseUrl,
    mediaUrl,
    cdnMediaUrl,
    signInError
}: { portalUrl: string, appAuthId: string, token: string, productIds: string[], subProductIds: any[], licenseUrl: string, mediaUrl: string, cdnMediaUrl?: string, signInError?: any }) 
{
    const isSignedIn = (token !== undefined) && (token !== ''),
        [user, setUser] = useState<any>(null);

    useEffect(() =>
    {
        async function loadSelf()
        {
            try
            {
                if (isSignedIn)
                {
                    const self: any = await fetch(`${portalUrl}/community/self?f=json&token=${token}`).then(rsp => { return rsp.json(); });
                    if (self.error !== undefined) throw new Error(self.error.message);
                    setUser(self);
                }
            }
            catch (syncErr)
            {
                console.log(syncErr); // TODO - relay this?
                setUser(null);
            }
        }
        loadSelf();
    }, []);

    const errMsg = (signInError !== undefined) && (signInError !== null) && (signInError.message !== 'UserCancel') ?
        <div className="warning-message">
            <div className="message-icon">
                <WarningIcon />
            </div>
            <div className="message-body">
                <p><FormattedMessage id="license.warning.anonymous.message" defaultMessage="A problem occurred while signing you in. Details are shown below. You are not signed in."
                />
                </p>
                <p className="error-message">{signInError.message}</p>
            </div>
        </div> : null;

    return (
        <Router basename="/">
            <div className="licensing-app">
                <Switch>
                    <Route path="/license/:productId/:subProdId">
                        <>
                            <AppLicensingHeader portalUrl={portalUrl} appAuthId={appAuthId} user={user} />
                            <div className="app-body">
                                <HomePage portalUrl={portalUrl} token={token} productIds={productIds} subProductIds={subProductIds} appAuthId={appAuthId} user={user} autoAuthorize={(signInError === undefined) || (signInError === null)} />
                                <div className="fixed inset-0 overflow-y-auto" style={{ zIndex: 999 }}>
                                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                        </div>
                                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-10 sm:align-middle sm:max-w-3xl sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                                            <div className="bg-white px-4 pt-5 pb-5 sm:p-6 sm:pb-5">
                                                <div className="h-5 mb-3 modal-header relative">
                                                    <div className="-mr-4 absolute flex right-0 sm:pr-4 top-0">
                                                        <Link to="/" className="rounded-md text-gray-300 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-white">
                                                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="modal-body">
                                                    <LicensePage portalUrl={portalUrl} token={token} licenseUrl={licenseUrl} appAuthId={appAuthId} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Route>
                    <Route path="/resources/:productId">
                        <>
                            <AppLicensingHeader portalUrl={portalUrl} appAuthId={appAuthId} user={user} />
                            <div className="app-body">
                                <DownloadPage portalUrl={portalUrl} token={token} mediaUrl={mediaUrl} appAuthId={appAuthId} />
                            </div>
                        </>
                    </Route>
                    <Route path="/downloads/sweet-offline-windows">
                        <>
                            <AppDownloadsHeader portalUrl={portalUrl} appAuthId={appAuthId} user={user} appTitle={'Sweet for ArcGIS - Offline - Windows'} />
                            <div className="app-body">
                                <DownloadPage portalUrl={portalUrl} token={token} mediaUrl={mediaUrl} appAuthId={appAuthId} product={{
                                    id: 'sweet-offline-windows',
                                    title: 'Sweet for ArcGIS - Offline - Windows',
                                    thumbnail: 'https://www.arcgis.com/sharing/rest/content/listings/1cf5a9c65fed4beb882b628470ddbe8b/info/screenshots/screenshot_1602678950687.png',
                                    url: 'https://www.esriuk.com/en-gb/arcgis/products/sweet-for-arcgis/overview',
                                    anonymous: true,
                                    single: true,
                                    mediaUrl: cdnMediaUrl || 'https://sweetpipelinestorage.blob.core.windows.net/esriuk-product-media',
                                    downloadPath: '/{filename}'
                                }} />
                            </div>
                        </>
                    </Route>
                    <Route path="/">
                        <>
                            <AppLicensingHeader portalUrl={portalUrl} appAuthId={appAuthId} user={user} />
                            <div className="app-body">
                                <HomePage portalUrl={portalUrl} token={token} productIds={productIds} subProductIds={subProductIds} appAuthId={appAuthId} user={user} autoAuthorize={(signInError === undefined) || (signInError === null)} />
                            </div>
                        </>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

const AppLicensingHeader = ({ portalUrl, user, appAuthId }: { portalUrl: string, user?: any, appAuthId?: string }) =>
{
    const location = useLocation();
    useEffect(() =>
    {
        document.title = (location.pathname.indexOf('downloads') >= 0 ?
                            'Application Downloads Hub | Esri UK' :
                            'Application Licensing Hub | Esri UK');
    }, [location]);

    return <header className="app-header">
        <nav className="esri-header-barrier" role="navigation">
            <div className="esri-header-wrap">
                <div className="esri-header -web">
                    <div className="esri-header-brand">
                        <a className="esri-header-brand-link" href="https://www.esriuk.com"><img src={esriukLogo} alt="Esri UK Logo" /></a>
                    </div>
                    <div className="esri-header-menus">
                        <div className="esri-header-menus-content">
                            <div className="esri-header-menus-menu">
                                <TopNavBar portalUrl={portalUrl} appAuthId={appAuthId} user={user} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <div className="bg-gray-200 border-b p-12 py-4 top-banner">
            <div className="container flex items-center justify-between">
                <div className="flex font-light space-x-4 tracking-wider">
                    <div className="border-opacity-25 border-r border-gray-600 pr-4">Esri UK</div>
                    <div className="border-opacity-25 pr-4">Application Licensing Hub</div>
                </div>
            </div>
        </div>
    </header>;
}

const TopNavBar = ({ portalUrl, user, appAuthId }: { portalUrl: string, user?: any, appAuthId?: string }) =>
{
    const location = useLocation(),
        oAuthUrl: string = `${portalUrl}/oauth2/authorize?client_id=${appAuthId}&response_type=token&redirect_uri=${window.location.href}`;
    
    return <ul className="esri-header-menus-list">
        {location.pathname !== '/' && <li className="esri-header-menus-item">
            <Link to="/" className="esri-header-menus-link">
                <span className="esri-header-menus-link-label">
                    <FormattedMessage id="app.header.button.home" defaultMessage="Home" />
                </span>
            </Link>
        </li>}
        {((user === undefined) || (user === null)) && (appAuthId !== undefined) && <li className="esri-header-menus-item">
            <a href={oAuthUrl} className="esri-header-menus-link">
                <span className="esri-header-menus-link-label">
                    <FormattedMessage id="app.header.button.signIn" defaultMessage="Sign in" />
                </span>
            </a>
        </li>}
        {((user !== undefined) && (user !== null)) && <li className="esri-header-menus-item">
            <a href={window.location.pathname} className="esri-header-menus-link">
                <span className="esri-header-menus-link-label">
                    <FormattedMessage id="app.header.button.signOut" defaultMessage="Sign out" />
                </span>
            </a>
        </li>}
    </ul>
}

const AppDownloadsHeader = ({ portalUrl, user, appAuthId, appTitle }: { portalUrl: string, user?: any, appAuthId?: string, appTitle?: string }) =>
{
    const location = useLocation();
    useEffect(() =>
    {
        document.title = (location.pathname.indexOf('downloads') >= 0 ?
                            'Application Downloads Hub | Esri UK' :
                            'Application Licensing Hub | Esri UK');
    }, [location]);
    
    return <header className="app-header">
        <nav className="esri-header-barrier" role="navigation">
            <div className="esri-header-wrap">
                <div className="esri-header -web">
                    <div className="esri-header-brand">
                        <a className="esri-header-brand-link" href="https://www.esriuk.com"><img src={esriukLogo} alt="Esri UK Logo" /></a>
                    </div>
                    <div className="esri-header-menus">
                        <div className="esri-header-menus-content">
                            <div className="esri-header-menus-menu">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <div className="bg-gray-200 border-b p-12 py-4 top-banner">
            <div className="container flex items-center justify-between">
                <div className="flex font-light space-x-4 tracking-wider">
                    <div className="border-opacity-25 border-r border-gray-600 pr-4">Esri UK</div>
                    <div className={`border-opacity-25 pr-4 ${(appTitle !== undefined ? 'border-r border-gray-600' : '')}`}>Application Downloads Hub</div>
                    {appTitle !== undefined ? <div className="border-opacity-25 pr-4 font-semibold">{appTitle}</div> : null}
                </div>
            </div>
        </div>
    </header>;
}

export default LicensingApp;
